const gutterWidth = 1 // rem
const outerMargin = 2 // rem

const containers = {
  xs: "500px",
  sm: "700px",
  md: "1000px",
  lg: "1200px",
}
const breakPoints = {
  mobile: "600",
  medium: "1000",
  large: "1200",
}

const screen = {
  mobile: `${breakPoints.mobile}px`,
  medium: `${breakPoints.medium}px`,
  large: `${breakPoints.large}px`,
}

const weights = {
  thin: "400",
  regular: "400",
  semibold: "400",
  bold: "700",
  black: "800",
}

const colors = {
  white: "#ffffff",

  grey900: "hsl(217, 26%, 16%)",
  grey800: "hsl(217, 22%, 31%)",
  grey700: "hsl(217, 18%, 40%)",
  grey600: "hsl(217, 18%, 48%)",
  grey500: "hsl(217, 18%, 56%)",
  grey400: "#50565d",
  grey300: "#8E9095",
  grey200: "hsl(217, 24%, 92%)",
  grey150: "hsl(217, 27%, 95%)",
  grey100: "hsl(217, 30%, 97%)",

  primary900: "#142D50",
  primary500: "#3095ff",

  tertiary600: "#F6A72A",
  tertiary500: "#FBB03B",
  tertiary400: "hsl(48, 87%, 60%)",
  tertiary300: "hsl(49, 89%, 75%)",
  tertiary200: "hsl(50, 92%, 85%)",
  tertiary150: "hsl(50, 93%, 91%)",
  tertiary100: "hsl(51, 95%, 97%)",
}

const headerColors = {
  backgroundColor: colors.text,
  borderColor: colors.textAccent,
}

const menuColors = {
  itemColor: "rgba(255, 255, 255, 0.75)",
  itemColorHover: "rgba(255, 255, 255, 0.9)",
}

const margins = {
  xxxl: "89px",
  xxl: "55px",
  xl: "34px",
  lg: "21px",
  md: "13px",
  nm: "8px",
  sm: "5px",
  0: "0",
  gutter: `${gutterWidth}rem`,
  outer: `${outerMargin}rem`,
}

const fonts = {
  sansSerif: "'Muli', 'Arial', sans-serif",
  serif: "'Martel', serif",
}

const buttons = {
  sm: {
    paddingVertical: "3px",
    paddingHorizontal: "5px",
  },
  nm: {
    paddingVertical: "5px",
    paddingHorizontal: "7px",
  },
  md: {
    paddingVertical: "7px",
    paddingHorizontal: "13px",
  },
  lg: {
    paddingVertical: "17px",
    paddingHorizontal: "27px",
  },
}

const fontSizes = {
  desktop: {
    lg: "38px",
    md: "18px",
    nm: "14px",
  },
  tablet: {
    lg: "32px",
    md: "18px",
    nm: "14px",
  },
  mobile: {
    lg: "20px",
    md: "14px",
    nm: "14px",
  },
}

const radius = {
  sm: "3px",
  nm: "5px",
  md: "8px",
  lg: "13px",
  xl: "21px",
  round: "50%",
}

const shadows = {
  xs: `1px 2px 4px hsla(0, 0%, 0%, 0.05), 1px 1px 2px hsla(0,0%, 0%, 0.02)`,
  sm: `1px 5px 10px  hsla(0, 0%, 0%, 0.25), 1px 2px 2px hsla(0,0%, 0%, 0.04)`,
  nm: `2px 10px 15px hsla(0, 0%, 0%, 0.04), 1px 2px 2px hsla(0,0%, 0%,0.02)`,
  md: `0 15px 25px hsla(0, 0%, 0%, 0.10), 0 3px 5px hsla(0,0%, 0%, 0.07)`,
  lg: `5px 20px 35px hsla(0, 0%, 0%, 0.25) , 0 3px 5px hsla(0,0%, 0%, 0.04)`,
  strong: `5px 20px 35px hsla(0, 0%, 0%, 0.45) , 0 3px 5px hsla(0,0%, 0%, 0.07)`,
  new: `7px 7px 0 rgba(0,0,0,0.15)`,
}

const Theme = {
  gutterWidth: `${gutterWidth}rem`,
  outerMargin: `${outerMargin}rem`,
  gutterCompensation: `${(gutterWidth / 2) * -1}rem`,
  halfGutterWidth: `${gutterWidth / 2}rem`,
  breakPoints: breakPoints,
  containers,
  screen: screen,
  colors: {
    ...colors,
    menu: { ...menuColors },
    header: { ...headerColors },
  },
  margins,
  radius,
  weights,
  fonts,
  fontSizes,
  buttons,
  shadows,
}

export default Theme

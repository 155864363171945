import React from "react"

export const Logo = () => (
  <svg viewBox="0 0 242 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M35.21 18.9H26.79V4.21C26.79 1.88 28.68 0 31 0C33.33 0 35.21 1.89 35.21 4.21V18.9Z"
        fill="#FBB03B"
      />
      <path
        d="M43.65 25.92L37.69 19.96L48.19 9.47C49.83 7.83 52.5 7.83 54.15 9.47C55.79 11.11 55.79 13.78 54.15 15.43L43.65 25.92Z"
        fill="#FBB03B"
      />
      <path
        d="M44.89 36.74V28.32H59.6C61.93 28.32 63.81 30.21 63.81 32.53C63.81 34.86 61.92 36.74 59.6 36.74H44.89Z"
        fill="#FBB03B"
      />
      <path
        d="M37.98 45.26L43.94 39.3L54.36 49.72C56 51.36 56 54.03 54.36 55.68C52.72 57.32 50.05 57.32 48.4 55.68L37.98 45.26Z"
        fill="#FBB03B"
      />
      <path
        d="M27.07 46.07H35.49V61.14C35.49 63.47 33.6 65.35 31.28 65.35C28.95 65.35 27.07 63.46 27.07 61.14V46.07Z"
        fill="#FBB03B"
      />
      <path
        d="M31.04 27.22L14.65 10.83C13.09 9.27 10.55 9.27 8.99 10.83C7.43 12.39 7.43 14.93 8.99 16.49L21.32 28.82H4C1.79 28.82 0 30.61 0 32.82C0 35.03 1.79 36.82 4 36.82H21.44L9.19 49.08C7.63 50.64 7.63 53.18 9.19 54.74C10.75 56.3 13.29 56.3 14.85 54.74L36.7 32.88L31.04 27.22Z"
        fill="#142D50"
      />
      <path d="M78 45.7V22.39H83.72V45.7H78Z" fill="#FBB03B" />
      <path
        d="M110.82 31.39V45.7H105.05V31.67C105.05 29.82 104.7 28.48 103.99 27.63C103.28 26.78 102.19 26.36 100.71 26.36C98.96 26.36 97.54 26.92 96.46 28.04C95.38 29.16 94.84 30.65 94.84 32.49V45.69H89.12V28.99C89.12 26.53 89 24.33 88.75 22.39H94.15L94.57 26.22C95.37 24.8 96.45 23.71 97.82 22.94C99.19 22.17 100.74 21.79 102.46 21.79C108.04 21.79 110.82 24.99 110.82 31.39Z"
        fill="#FBB03B"
      />
      <path
        d="M123.38 26.73V37.16C123.38 39.9 124.66 41.27 127.21 41.27C127.92 41.27 128.69 41.15 129.52 40.9V45.47C128.5 45.84 127.27 46.02 125.83 46.02C123.18 46.02 121.15 45.28 119.74 43.8C118.32 42.32 117.62 40.2 117.62 37.43V26.73H113.14V22.39H117.62V16.71L123.39 14.77V22.39H129.58V26.73H123.38Z"
        fill="#FBB03B"
      />
      <path
        d="M154.76 22.39V45.7H149.13V42.1C148.39 43.39 147.41 44.38 146.18 45.05C144.95 45.73 143.53 46.07 141.93 46.07C136.24 46.07 133.39 42.87 133.39 36.47V22.39H139.11V36.51C139.11 38.2 139.46 39.45 140.15 40.25C140.84 41.05 141.91 41.45 143.36 41.45C145.05 41.45 146.41 40.9 147.42 39.79C148.44 38.68 148.94 37.21 148.94 35.36V22.39H154.76Z"
        fill="#FBB03B"
      />
      <path
        d="M180.89 31.39V45.7H175.12V31.67C175.12 29.82 174.77 28.48 174.06 27.63C173.35 26.78 172.26 26.36 170.78 26.36C169.03 26.36 167.61 26.92 166.53 28.04C165.45 29.16 164.91 30.65 164.91 32.49V45.69H159.19V28.99C159.19 26.53 159.07 24.33 158.82 22.39H164.22L164.64 26.22C165.44 24.8 166.52 23.71 167.89 22.94C169.26 22.17 170.81 21.79 172.53 21.79C178.1 21.79 180.89 24.99 180.89 31.39Z"
        fill="#142D50"
      />
      <path
        d="M205.58 34.35H189.52C189.64 36.84 190.27 38.68 191.39 39.87C192.51 41.05 194.18 41.65 196.4 41.65C198.95 41.65 201.32 40.82 203.51 39.16L205.17 43.13C204.06 44.02 202.7 44.74 201.08 45.28C199.46 45.82 197.83 46.09 196.16 46.09C192.34 46.09 189.34 45.01 187.16 42.86C184.97 40.71 183.88 37.75 183.88 34C183.88 31.63 184.36 29.52 185.31 27.68C186.26 25.83 187.6 24.4 189.33 23.36C191.05 22.33 193.01 21.81 195.19 21.81C198.39 21.81 200.92 22.85 202.78 24.93C204.64 27.01 205.57 29.86 205.57 33.49V34.35H205.58ZM191.57 27.31C190.6 28.22 189.98 29.53 189.7 31.26H200.55C200.37 29.51 199.83 28.18 198.96 27.29C198.08 26.4 196.87 25.95 195.34 25.95C193.8 25.95 192.54 26.4 191.57 27.31Z"
        fill="#142D50"
      />
      <path
        d="M236.8 22.39H241.8L233.68 45.7H229.21L224.09 30.42L219.09 45.7H214.58L206.46 22.39H211.71L217.04 38.82L222.41 22.39H226.1L231.47 38.96L236.8 22.39Z"
        fill="#142D50"
      />
      <path
        d="M80.86 19.89C82.9532 19.89 84.65 18.1932 84.65 16.1C84.65 14.0068 82.9532 12.31 80.86 12.31C78.7668 12.31 77.07 14.0068 77.07 16.1C77.07 18.1932 78.7668 19.89 80.86 19.89Z"
        fill="#FBB03B"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="241.8" height="65.35" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Logo

import React from "react"

const Arrow = () => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M10.87 6.15L5.13 0.410001C4.58 -0.139999 3.7 -0.139999 3.15 0.410001C2.6 0.960001 2.6 1.84 3.15 2.39L7.47 6.71H1.4C0.63 6.71 0 7.34 0 8.11C0 8.88 0.63 9.51 1.4 9.51H7.51L3.22 13.8C2.67 14.35 2.67 15.23 3.22 15.78C3.77 16.33 4.65 16.33 5.2 15.78L12.85 8.13L10.87 6.15Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="12.85" height="16.19" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Arrow

import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import {
  PageContainer,
  ContentContainer,
  InfoContainer,
  MessageContainer,
  FormContainer,
  FullWidth,
} from "../components/Containers"
import Theme from "../components/Theme"
import Layout from "../components/layout"
import { Title, Text } from "../components/Text"
import SubscribeForm from "../components/SubscibeForm"
import People from "../components/People"
import Logo from "../components/Logo"

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Muli:400,700,900&display=swap');
  *, *:focus {
    outline: 0;
    box-sizing: border-box !important;
  }
  html {
    font-size: 16px;
  }
  html,
  body,
  div,
  input,
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: white;
    font-family: "Muli", sans-serif;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  svg {
    background: transparent;
  }

  form {
    font-size: ${props => props.theme.fontSizes.sm};
  }

  p {
    line-height: 1.55;
  }
`

const IndexPage = () => (
  <ThemeProvider theme={Theme}>
    <Layout>
      <GlobalStyle />
      <FullWidth>
        <PageContainer>
          <ContentContainer>
            <InfoContainer>
              {/* <People /> */}
              <MessageContainer>
                <Logo />
                <Title size="lg" color="primary900" bold>
                  For the best independent trainers in technology
                </Title>
              </MessageContainer>
            </InfoContainer>

            <FormContainer>
              <SubscribeForm />
            </FormContainer>
          </ContentContainer>
        </PageContainer>
      </FullWidth>
    </Layout>
  </ThemeProvider>
)

export default IndexPage

import styled from "styled-components"
export const FullWidth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  padding: 37px 20px 37px 20px;
  @media (max-width: 1024px) {
    padding: 37px 80px 37px 80px;
  }
  @media (max-width: 600px) {
    padding: 37px 20px 37px 20px;
  }
`
export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1160px;
  min-height: 100vh;
  @media (max-width: 600px) {
    flex-direction: column;
    min-height: auto;
  }
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 345px;
  grid-column-gap: 110px;
  box-sizing: border-box;
  min-width: 100%;
  @media (max-width: 1024px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-column-gap: 40px;
    align-items: center;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  min-width: 450px;
  background-image: url("./peopleInCircle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  height: 800px;
  @media (max-width: 1024px) {
    padding: 0;
    width: 100%;
    min-width: 520px;
  }
  @media (max-width: 600px) {
    height: 400px;
    min-width: auto;
  }
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;

  @media (max-width: 1024px) {
    margin-top: 30px;
    width: 100%;
    max-width: 100%;
  }
`

export const MessageContainer = styled.div`
  position: absolute;
  left: 0;
  max-width: 65%;
  background: transparent;
  h2 {
    line-height: 48px;
  }
  svg {
    width: 242px;
    margin-bottom: 18px;
    margin-left: -25px;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
    h2 {
      line-height: 25px;
    }
    svg {
      width: 179px;
      margin-bottom: 13px;
      margin-left: -19px;
    }
  }
`

import styled from "styled-components"

export const MainTitle = styled.h1`
  background: transparent;
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.grey};
  font-weight: ${props => {
    if (props.black) return 900
    if (props.bold) return 700
    return 500
  }};
  font-size: ${({ size, theme }) => theme.fontSizes.desktop[size]};
  text-transform: ${props => {
    if (props.capitalize) return "capitalize"
    if (props.uppercase) return "uppercase"
    return "none"
  }};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  line-height: 1.25;
  text-align: ${props => {
    if (props.center) return "center"
    if (props.right) return "right"
    if (props.left) return "left"
    return "auto"
  }};
  padding: 0;
  margin: 0;
  @media (max-width: 600px) {
    font-size: ${({ size, theme }) => theme.fontSizes.mobile[size]};
  }
`
export const Title = MainTitle.withComponent("h2")
export const Subtitle = MainTitle.withComponent("h3")
export const Paragraph = MainTitle.withComponent("p")
export const Text = styled(Paragraph)`
  line-height: 1.5;
`

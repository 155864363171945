import React from "react"
import styled from "styled-components"
import { Text, Subtitle } from "./Text"
import Arrow from "./Arrow"

const NewsletterWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 345px;
  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 21px;
  }
  @media (max-width: 1024px) {
    max-width: 345px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  margin: 0;
  margin-bottom: 21px;
`

const NewsletterField = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  line-height: 50px;
  outline: none;
  color: ${({ theme }) => theme.colors.grey300};
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 10px;
  padding: 0 14px;
  margin-bottom: 21px;
  outline: none;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    color: ${({ theme }) => theme.grey300};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.grey500};
  }
  &:first-of-type {
    margin-top: 21px;
  }
`

const NewsletterButton = () => (
  <NewsletterButtonContainer>
    <Arrow />
    Send me an invite
  </NewsletterButtonContainer>
)

const NewsletterButtonContainer = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 49px;
  line-height: 49px;
  width: 100%;
  padding: 0 14px;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 21px;
  background: ${props => props.theme.colors.tertiary500};
  border-radius: 10px;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: white;
  }
  svg {
    width: 13px;
    margin-right: 7px;
    fill: ${({ theme }) => theme.colors.white};
    background-color: inherit;
  }
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.nm};
    line-height: 18px;
  }
`

const SubscribeForm = () => (
  <NewsletterWrapper>
    <Subtitle size="nm" color="grey400" margin="nm" bold>
      Leave us your email and we'll send you an invite to set up your profile.
    </Subtitle>

    <Form
      action="https://intunue.us4.list-manage.com/subscribe/post?u=e0d82e4d41ebcb02ceb02d6c0&amp;id=834a2cf397"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      novalidate
    >
      <NewsletterField
        type="text"
        placeholder="Name"
        name="FNAME"
        id="mce-FNAME"
      />
      <NewsletterField
        type="email"
        placeholder="Email"
        name="EMAIL"
        className="required email"
        id="mce-EMAIL"
      />
      <NewsletterButton
        className="button"
        type="submit"
        value="Subscribe"
        name="subscribe"
        id="mc-embedded-subscribe"
      />
    </Form>
    <Text size="nm" color="grey400">
      If you want to teach and get connected directly to business opportunities,
      drop us your details.
    </Text>

    <Text size="nm" color="grey400">
      Intunew is the platform for people who want to learn what you can teach.
    </Text>
  </NewsletterWrapper>
)

export default SubscribeForm
